<template>
    <v-row class="text-center mt-5">
        <v-col>
            <h3>กลับไปหน้า Login</h3>
        </v-col>
    </v-row>
</template>

<script>
export default {
    name: 'Logout',
    mounted() {
        this.logOff();
    }, 
    methods: {
        logOff() {
            // this.$router.push("/login")
            this.$store.dispatch({
                type: "doLogout"
            });
        },
    },
}
</script>

<style>

</style>
